const logo = ({ width = 367, height = 172 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 367 172"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M59.1245 147.004C35.9315 148.542 20.0589 146.669 13.3975 141.607C6.73608 136.545 9.56677 128.507 21.5523 118.453C33.5379 108.398 54.173 96.7492 80.9995 84.8946C107.826 73.0399 139.713 61.479 172.861 51.5889C206.01 41.6989 239.023 33.8967 267.967 29.1119C296.911 24.3271 320.567 22.7614 336.115 24.6013C351.664 26.4411 358.45 31.609 355.666 39.4893C352.881 47.3695 340.643 57.63 320.41 69.0482L278.659 74.1073C292.742 66.1603 301.259 59.0191 303.197 53.5345C305.135 48.0499 300.412 44.4531 289.59 43.1726C278.768 41.8921 262.304 42.9818 242.159 46.312C222.014 49.6422 199.038 55.0724 175.967 61.9558C152.896 68.8391 130.703 76.8855 112.032 85.1362C93.3607 93.387 78.9988 101.494 70.657 108.492C62.3151 115.49 60.345 121.084 64.9813 124.607C69.6175 128.13 80.6648 129.434 96.8069 128.364L59.1245 147.004Z"
      fill="#FF9100"
    />
    <circle cx="183" cy="86" r="82" fill="#822FAF" />
    <path
      d="M309.608 24.216C332.283 22.9317 347.507 25.0625 353.465 30.3545C359.423 35.6466 355.865 43.8768 343.214 54.0648C330.564 64.2527 309.354 75.9689 282.112 87.8175C254.869 99.6661 222.743 111.148 189.56 120.894C156.377 130.641 123.536 138.242 94.9495 142.792C66.363 147.342 43.236 148.648 28.3238 146.556C13.4115 144.464 7.34259 139.062 10.84 130.992C14.3375 122.923 27.2538 112.527 48.0503 101.042L89.0993 96.3749C74.6252 104.368 65.6355 111.604 63.2013 117.22C60.7671 122.836 64.991 126.596 75.3698 128.052C85.7486 129.508 101.845 128.599 121.741 125.432C141.637 122.266 164.494 116.975 187.589 110.192C210.684 103.408 233.044 95.4171 252.004 87.1705C270.964 78.924 285.726 70.7696 294.531 63.6789C303.336 56.5882 305.812 50.86 301.665 47.1768C297.518 43.4936 286.923 42.0106 271.141 42.9045L309.608 24.216Z"
      fill="#FF9100"
    />
  </svg>
)

export default logo
